// import React, { useEffect } from 'react';

// const AdminPanel = () => {
//   useEffect(() => {
//     // Dynamically add Material Icons stylesheet
//     const link = document.createElement('link');
//     link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
//     link.rel = 'stylesheet';
//     document.head.appendChild(link);

//     // Clean up function to remove the link when component unmounts
//     return () => {
//       document.head.removeChild(link);
//     };
//   }, []);

//   return (
//     <div className="bg-blue-50 min-h-screen">
//       <div className="flex flex-row pt-24 px-10 pb-4">
//         <div className="w-2/12 mr-6">
//           <div className="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
//             <a href="#" className="inline-block text-gray-600 hover:text-blue-800 my-4 w-full">
//               <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}></span>
//               Home
//               <span className="material-icons-outlined float-right" style={{ color: '#00416A' }}></span>
//             </a>
//             <a href="#" className="inline-block text-gray-600 hover:text-blue-800 my-4 w-full">
//               <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}></span>
//               Some menu item
//               <span className="material-icons-outlined float-right" style={{ color: '#00416A' }}></span>
//             </a>
//             <a href="#" className="inline-block text-gray-600 hover:text-blue-800 my-4 w-full">
//               <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}></span>
//               Another menu item
//               <span className="material-icons-outlined float-right" style={{ color: '#00416A' }}></span>
//             </a>
//           </div>

//           <div className="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
//             <a href="#" className="inline-block text-gray-600 hover:text-blue-800 my-4 w-full">
//               <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}></span>
//               Profile
//               <span className="material-icons-outlined float-right" style={{ color: '#00416A' }}></span>
//             </a>
//             <a href="#" className="inline-block text-gray-600 hover:text-blue-800 my-4 w-full">
//               <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}></span>
//               Settings
//               <span className="material-icons-outlined float-right" style={{ color: '#00416A' }}></span>
//             </a>
//             <a href="#" className="inline-block text-gray-600 hover:text-blue-800 my-4 w-full">
//               <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}></span>
//               Log out
//               <span className="material-icons-outlined float-right" style={{ color: '#00416A' }}></span>
//             </a>
//           </div>
//         </div>

//         <div className="w-10/12">
//           <div className="flex flex-row">
//             <div
//               className="bg-no-repeat bg-[#00416A] border border-red-300 rounded-xl w-7/12 mr-2 p-6"
//               style={{
//                 backgroundImage: 'url(https://previews.dropbox.com/p/thumb/AAvyFru8elv-S19NMGkQcztLLpDd6Y6VVVMqKhwISfNEpqV59iR5sJaPD4VTrz8ExV7WU9ryYPIUW8Gk2JmEm03OLBE2zAeQ3i7sjFx80O-7skVlsmlm0qRT0n7z9t07jU_E9KafA9l4rz68MsaZPazbDKBdcvEEEQPPc3TmZDsIhes1U-Z0YsH0uc2RSqEb0b83A1GNRo86e-8TbEoNqyX0gxBG-14Tawn0sZWLo5Iv96X-x10kVauME-Mc9HGS5G4h_26P2oHhiZ3SEgj6jW0KlEnsh2H_yTego0grbhdcN1Yjd_rLpyHUt5XhXHJwoqyJ_ylwvZD9-dRLgi_fM_7j/p.png?fv_content=true&size_mode=5)',
//                 backgroundPosition: '90% center',
//               }}
//             >
//               <p className="text-5xl text-white">
//                 Welcome <br />
//                 <strong>Lorem Ipsum</strong>
//               </p>
//               <span className="bg-red-300 text-xl text-white inline-block rounded-full mt-12 px-8 py-2">
//                 <strong>01:51</strong>
//               </span>
//             </div>

//             <div
//               className="bg-no-repeat bg-[#00416A] border border-orange-300 rounded-xl w-5/12 ml-2 p-6"
//               style={{
//                 backgroundImage: 'url(https://previews.dropbox.com/p/thumb/AAuwpqWfUgs9aC5lRoM_f-yi7OPV4txbpW1makBEj5l21sDbEGYsrC9sb6bwUFXTSsekeka5xb7_IHCdyM4p9XCUaoUjpaTSlKK99S_k4L5PIspjqKkiWoaUYiAeQIdnaUvZJlgAGVUEJoy-1PA9i6Jj0GHQTrF_h9MVEnCyPQ-kg4_p7kZ8Yk0TMTL7XDx4jGJFkz75geOdOklKT3GqY9U9JtxxvRRyo1Un8hOObbWQBS1eYE-MowAI5rNqHCE_e-44yXKY6AKJocLPXz_U4xp87K4mVGehFKC6dgk_i5Ur7gspuD7gRBDvd0sanJ9Ybr_6s2hZhrpad-2WFwWqSNkh/p.png?fv_content=true&size_mode=5)',
//                 backgroundPosition: '100% 40%',
//               }}
//             >
//               <p className="text-5xl text-white">
//                 Open Applications<br />
//                 <strong>23</strong>
//               </p>
//               <a
//                 href="#"
//                 className="bg-orange-300 text-xl text-white underline hover:no-underline inline-block rounded-full mt-12 px-8 py-2"
//               >
//                 <strong>See messages</strong>
//               </a>
//             </div>
//           </div>
//           <div className="flex flex-row h-64 mt-6">
//             <div className="bg-white rounded-xl shadow-lg px-6 py-4 w-4/12">a</div>
//             <div className="bg-white rounded-xl shadow-lg mx-6 px-6 py-4 w-4/12">b</div>
//             <div className="bg-white rounded-xl shadow-lg px-6 py-4 w-4/12">c</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminPanel;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminPanel = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const secretKey = localStorage.getItem('secret_key');
      const token = localStorage.getItem('accessToken');

      if (!secretKey || !token) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch('https://profit-backend.onrender.com/api/user-data/', {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Secret-Key': secretKey,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          navigate('/login');
        }
      } catch (err) {
        setError('Failed to load data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();

    // Dynamically add Material Icons stylesheet
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Clean up function to remove the link when component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, [navigate]);

  const handleOpenApplicationClick = () => {
    setShowModal(true);
  };

  const handleProceedToApplication = () => {
    navigate('/open-application');
    setShowModal(false);
  };

  const handleReviewProfile = () => {
    navigate('/profile');
    setShowModal(false);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        {/* <span className="loading loading-ring loading-xs"></span>
        <span className="loading loading-ring loading-sm"></span>
        <span className="loading loading-ring loading-md"></span> */}
        <span className="loading loading-ring loading-lg"></span>
      </div>
    );
  }

  if (error) {
    return <div className="text-center mt-10">{error}</div>;
  }

  return (
    <div className="bg-blue-50 min-h-screen px-4 py-6 mt-10">
      <div className="flex flex-col lg:flex-row pt-10">
        {/* Sidebar */}
        <div className="w-full lg:w-2/12 mb-8 lg:mb-0 lg:mr-6">
          <div className="bg-white rounded-xl shadow-lg mb-6 p-6">
            <a href="/" className="block text-gray-600 hover:text-blue-800 my-4">
              <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}>
               
              </span>
              Home
            </a>
            <a href="/wallet" className="block text-gray-600 hover:text-blue-800 my-4">
              <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}>
               
              </span>
              Compare
            </a>
            <button
              onClick={handleOpenApplicationClick}
              className="block text-gray-600 hover:text-blue-800 my-4 text-left w-full focus:outline-none"
            >
              <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}>
                
              </span>
              Open Applications
            </button>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-6">
            <a href="/profile" className="block text-gray-600 hover:text-blue-800 my-4">
              <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}>
                
              </span>
              Profile
            </a>
            <a href="/" className="block text-gray-600 hover:text-blue-800 my-4">
              <span className="material-icons-outlined float-left pr-2" style={{ color: '#00416A' }}>
               
              </span>
              Log out
            </a>
          </div>
        </div>

        {/* Main Content */}
        <div className="w-full lg:w-10/12">
          <div className="flex flex-col lg:flex-row">
            <div
              className="bg-[#00416A] border border-red-300 rounded-xl p-6 mb-6 lg:mb-0 lg:mr-4 w-full lg:w-7/12 bg-cover"
              style={{
                backgroundImage: 'url(https://example.com/image1.png)',
                backgroundPosition: 'right center',
              }}
            >
              <p className="text-3xl lg:text-5xl text-white">
                Welcome <br />
                <strong>{userData.username}</strong>
              </p>
              <span className="bg-red-300 text-xl text-white inline-block rounded-full mt-8 px-6 py-2">
                <strong>{new Date().toLocaleTimeString()}</strong>
              </span>
            </div>

            <div
              className="bg-[#00416A] border border-orange-300 rounded-xl p-6 w-full lg:w-5/12 bg-cover"
              style={{
                backgroundImage: 'url(https://example.com/image2.png)',
                backgroundPosition: 'center',
              }}
            >
              <p className="text-3xl lg:text-5xl text-white">
                Open Applications<br />
                <strong>{userData.applications_open}</strong>
              </p>
              <button
                onClick={handleOpenApplicationClick}
                className="bg-orange-300 text-lg lg:text-xl text-white inline-block rounded-full mt-8 px-6 py-2 focus:outline-none"
              >
                <strong>Open Application</strong>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="bg-white rounded-lg shadow-lg p-6 z-10 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4 text-center">
              Attention: Please Review and Update Your Profile
            </h2>
            <p className="mb-4">
              Before applying, ensure that your profile information is complete and accurate. Missing or incorrect details in your profile could affect your application.
            </p>
            <div className="mb-4 text-center">
              <button
                onClick={handleReviewProfile}
                className="text-[#00416A] underline focus:outline-none"
              >
                Review/Update Profile
              </button>
            </div>
            <p className="mb-6">
              Once your profile is updated, return to the job listing and complete your application.
            </p>
            <div className="flex justify-center">
              <button
                onClick={handleProceedToApplication}
                className="bg-[#00416A] text-white px-4 py-2 rounded mr-2 focus:outline-none"
              >
                Proceed to Application
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;




// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// const AdminPanel = () => {
//   const [userData, setUserData] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchUserData = async () => {
//       const secretKey = localStorage.getItem('secret_key');
//       const token = localStorage.getItem('accessToken');

//       if (!secretKey || !token) {
//         console.error('Secret key or token is missing.');
//         navigate('/login');
//         return;
//       }

//       console.log('Stored secret_key:', secretKey);
//       console.log('Stored accessToken:', token);

//       try {
//         const response = await fetch('http://127.0.0.1:8000/api/user-data/', {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//             'X-Secret-Key': secretKey,
//             'Content-Type': 'application/json'
//           }
//         });

//         console.log('Response Status:', response.status); // Debugging response status

//         if (response.ok) {
//           const data = await response.json();
//           console.log('Fetched user data:', data); // Log fetched data
//           setUserData(data);
//         } else {
//           const errorData = await response.json(); // Log error details from server
//           console.error('Failed to fetch user data. Status:', response.status, 'Error:', errorData);
//           navigate('/login');
//         }
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         navigate('/login');
//       }
//     };

//     fetchUserData();

//     const link = document.createElement('link');
//     link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
//     link.rel = 'stylesheet';
//     document.head.appendChild(link);

//     return () => {
//       document.head.removeChild(link);
//     };
//   }, [navigate]);

//   if (!userData) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="bg-blue-50 min-h-screen">
//       {/* Component content */}
//     </div>
//   );
// };

// export default AdminPanel;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const EducationForm = () => {
//     const [degree, setdegree] = useState('');
//     const [fieldofstudy, setfieldofstudy] = useState('');
//     const [institution, setinstitution] = useState('');
//     const [startdate, setstartdate] = useState('');
//     const [enddate, setenddate] = useState('');
//     const [grade, setgrade] = useState('');
//     const [message, setMessage] = useState('');
//     const [error, setError] = useState('');
//     const [username, setusername] = useState('');

//     useEffect(() => {
//         const key = localStorage.getItem('username');
//         setusername(key || ''); // Default to empty string if not found
//     }, []);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://127.0.0.1:8000/api/add-education/', {
//                 degree,
//                 fieldofstudy,
//                 institution,
//                 startdate,
//                 enddate,
//                 grade,
//                 username,
//             });
//             setMessage('Education information submitted successfully!');
//             setError('');
//         } catch (error) {
//             setError('An error occurred while submitting the form.');
//             setMessage('');
//         }
//     };

//     return (
//         <div className="flex justify-center items-center min-h-screen mt-20 inset-0 z-50 overflow-y-auto">
//             <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
//                 <h2 className="text-xl font-medium leading-normal text-gray-800 dark:text-gray-200 text-a;ign-centre">Education</h2>
//                 <form onSubmit={handleSubmit} className="space-y-6">
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Degree:</label>
//                         <input
//                             type="text"
//                             value={degree}
//                             onChange={(e) => setdegree(e.target.value)}
//                             required
//                             className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                             placeholder="e.g., B.Sc., M.A., etc."
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Field of Study:</label>
//                         <input
//                             type="text"
//                             value={fieldofstudy}
//                             onChange={(e) => setfieldofstudy(e.target.value)}
//                             required
//                             className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                             placeholder="e.g., Computer Science"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">University:</label>
//                         <input
//                             type="text"
//                             value={institution}
//                             onChange={(e) => setinstitution(e.target.value)}
//                             required
//                             className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                             placeholder="e.g., Harvard University"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Start Year:</label>
//                         <input
//                             type="text"
//                             value={startdate}
//                             onChange={(e) => setstartdate(e.target.value)}
//                             required
//                             className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                             placeholder="e.g., 2020"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">End Year:</label>
//                         <input
//                             type="text"
//                             value={enddate}
//                             onChange={(e) => setenddate(e.target.value)}
//                             required
//                             className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                             placeholder="e.g., 2024"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Grade:</label>
//                         <input
//                             type="text"
//                             value={grade}
//                             onChange={(e) => setgrade(e.target.value)}
//                             required
//                             className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
//                             placeholder="e.g., A+"
//                         />
//                     </div>
//                     <button
//                         type="submit"
//                         className="bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full"
//                     >
//                         Submit
//                     </button>
//                 </form>
//                 {message && <p className="mt-4 text-green-600 text-center">{message}</p>}
//                 {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
//             </div>
//         </div>
//     );
// };

// export default EducationForm;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation

const EducationForm = () => {
    const [educationRecords, setEducationRecords] = useState([{
        degree: '',
        fieldofstudy: '',
        institution: '',
        startdate: '',
        enddate: '',
        grade: '',
    }]);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const navigate = useNavigate(); // React Router navigation hook

    useEffect(() => {
        const key = localStorage.getItem('username');
        setUsername(key || ''); // Default to empty string if not found
    }, []);

    const handleInputChange = (index, event) => {
        const values = [...educationRecords];
        values[index][event.target.name] = event.target.value;
        setEducationRecords(values);
    };

    const handleAddMore = () => {
        setEducationRecords([...educationRecords, {
            degree: '',
            fieldofstudy: '',
            institution: '',
            startdate: '',
            enddate: '',
            grade: '',
        }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            for (const record of educationRecords) {
                await axios.post('https://profit-backend.onrender.com/api/add-education/', {
                    ...record,
                    username,
                });
            }
            setMessage('Education information submitted successfully!');
            setError('');
        } catch (error) {
            setError('An error occurred while submitting the form.');
            setMessage('');
        }
    };

    const handleSkip = () => {
        navigate('/experience'); // Redirect to home page
    };

    const handleAddAndNext = async () => {
        try {
            await handleSubmit(new Event('submit')); // Submit current data
            navigate('/experience'); // Redirect to next page
        } catch (error) {
            setError('An error occurred while submitting the form.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen mt-20 inset-0 z-50 overflow-y-auto">
            <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
                <h2 className="text-xl font-medium leading-normal text-gray-800 dark:text-gray-200 text-center">Education</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {educationRecords.map((record, index) => (
                        <div key={index}>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Degree:</label>
                                <input
                                    type="text"
                                    name="degree"
                                    value={record.degree}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., B.Sc., M.A., etc."
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Field of Study:</label>
                                <input
                                    type="text"
                                    name="fieldofstudy"
                                    value={record.fieldofstudy}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., Computer Science"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">University:</label>
                                <input
                                    type="text"
                                    name="institution"
                                    value={record.institution}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., Harvard University"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Start Year:</label>
                                <input
                                    type="date"
                                    name="startdate"
                                    value={record.startdate}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., 2020"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">End Year:</label>
                                <input
                                    type="date"
                                    name="enddate"
                                    value={record.enddate}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., 2024"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Grade:</label>
                                <input
                                    type="text"
                                    name="grade"
                                    value={record.grade}
                                    onChange={(e) => handleInputChange(index, e)}
                                    required
                                    className="bg-transparent border-b border-black dark:border-gray-200 focus:outline-none focus:border-blue-600 text-sm w-full py-2"
                                    placeholder="e.g., A+"
                                />
                            </div>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddMore}
                        className="bg-gray-500 text-white px-4 py-2 rounded-full mt-4"
                    >
                        Add More
                    </button>
                    <div className="flex justify-between mt-6">
                        <button
                            type="button"
                            onClick={handleSkip}
                            className="bg-gray-500 text-white px-4 py-2 rounded-full"
                        >
                            Skip
                        </button>
                        <button
                            type="button"
                            onClick={handleAddAndNext}
                            className="bg-gradient-to-br from-green-400 to-blue-600 text-white px-4 py-2 rounded-full"
                        >
                            Add & Next
                        </button>
                    </div>
                </form>
                {message && <p className="mt-4 text-green-600 text-center">{message}</p>}
                {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
            </div>
        </div>
    );
};

export default EducationForm;
